const ESTABLISHMENT = {

    GET_EMPLOYERS: 'GET_EMPLOYERS',
    GET_BRANCHES: 'GET_BRANCHES',

    GET_ESTABLISHMENT_IDS: 'GET_ESTABLISHMENT_IDS',

    ADD_ESHTABLISHMENTS: "ADD_ESHTABLISHMENTS",

    UPLOAD_ESTABLISHMENTS: 'UPLOAD_ESTABLISHMENTS',

};

export default ESTABLISHMENT;
