

const SALARY_STATUS = {
    GET_SALARY_STATUS: 'GET_SALARY_STATUS',

    GET_SALARY_STATUS_DETAIL: 'GET_SALARY_STATUS_DETAIL',
    
    DOWNLOAD_SALARY_STATUS_DETAIL: 'DOWNLOAD_SALARY_STATUS_DETAIL',
};

export default SALARY_STATUS
