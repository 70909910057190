import React, { useState, useRef, useImperativeHandle, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import { Form, Formik } from "formik";
import scheme from "./Validations";
import { CButton, CField } from "../../uiComponents";

const CReason = forwardRef(({ onSubmit = () => { }, title = '' }, ref) => {

    const form = useRef(null);

    const reduxState = useSelector(({ employees }) => {
        return {
            loading: employees.replaceCardLoading || employees?.cardActivateOrBlockLoading,
        };
    });

    const { loading } = reduxState;

    const [isOpen, setIsOpen] = useState(false);

    useImperativeHandle(ref, () => ({
        setIsOpen
    }));

    const onCancel = () => {
        if (!loading) {
            setIsOpen(false);
        }
    };

    return <Modal
        title={title || 'Enter Reason'}
        width={450}
        centered
        open={isOpen}
        maskClosable={false}
        onCancel={onCancel}
        cancelButtonProps={{ hidden: true }}
        okButtonProps={{ hidden: true }}
        destroyOnClose
    >
        <Formik
            innerRef={form}
            validationSchema={scheme}
            validateOnChange={true}
            validateOnBlur={true}
            initialValues={{ reason: '' }}
            onSubmit={onSubmit}
        >
            {({ errors, touched, handleSubmit, values, setFieldTouched, submitCount, handleChange, setFieldValue }) => {
                let fileError = submitCount ? errors?.attachmentFile : touched?.attachmentFile && errors?.attachmentFile;
                return <Form>
                    <CField
                        name="reason"
                        placeholder="Reason (Opional)"
                        fieldType="textArea"
                        containerClass={"flex0 margin-right_zero"}
                        error={submitCount ? errors?.reason : touched?.reason && errors.reason}
                        onBlur={() => setFieldTouched("reason", true, true)}
                        disabled={loading}
                        value={values?.reason}
                        onChange={(e) => handleChange('reason')(e.target.value)}
                    />

                    <CButton
                        containerClass="flex0 margin-right_zero"
                        onClick={handleSubmit}
                        type="submit"
                        htmlType="submit"
                        title={'Submit'}
                        loading={loading}
                        disabled={loading}
                    />
                </Form>
            }}
        </Formik>

    </Modal>
});

export default React.memo(CReason);