import React, { useEffect, useState } from "react";
import { FileIcon } from "../../assets/svg";
import { CButton, CLoading, CPopup } from "../../uiComponents";
import { ACTION, CLIENT_APPROVAL_STATUS, NATIONALITY, TOPIC } from "../../utils/constants";
import { countryData } from "../../utils/countryData";
import { _readableTextCap, formatter, getObjByKey, getPhoneNumber, IsExchangeHouseBranch, IsExchangeHouseBranchUser, IsExchangeHouseUser, MappedElement, openFileInBrowser, readableText, renderDate, renderDateAndTime, renderDocuments } from "../../utils/methods";
import moment from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { getSingleApproval } from "../../store/actions/Approval.action";
import { Empty } from "antd";

function Details({ selectedData, loading, takeAction, toggleRemarksDrawer }) {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState({});

  const reduxState = useSelector(({ approval, auth }) => {
    return {
      loading: approval?.getSingleApprovalLoading,
      approval: approval?.approval,
      user: auth?.user,
    };
  });

  let isUser = IsExchangeHouseBranchUser() || IsExchangeHouseUser() || IsExchangeHouseBranch();

  const employees = selected?.employeesCurrentDetails && selected?.employeesCurrentDetails?.length ? selected?.employeesCurrentDetails : selected?.employees;

  useEffect(() => {
    let payload = { _id: selectedData?._id };
    dispatch(getSingleApproval(payload));
  }, []);

  useEffect(() => {
    setSelected(reduxState?.approval);
  }, [reduxState?.approval]);

  let documents =
    selected?.topic !== TOPIC?.EMPLOYEE_ONBOARD_REQUEST
      ? selected?.client?.pendingChanges?.documents?.length
        ? selected?.client?.pendingChanges?.documents
        : selected?.client?.documents
      : selected?.employee?.documents;

  const renderImage = (file, fileName, subTitle = "") => {
    try {
      return (
        <div style={{ display: "flex", flexBasis: "100%" }}>
          <div className="c-preview-list flex1">
            <div className="preview flex1">
              <div
                className="left"
                onClick={() =>
                  openFileInBrowser(file?.url || file, true, fileName)
                }
              >
                <div className="thumbnail">
                  <FileIcon />
                </div>
                <span>
                  <div>{file?.name || fileName || file?.documentNumber}</div>
                  <p>
                    {subTitle?.length ? subTitle : renderDate(file?.expiryDate)}
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      );
    } catch (e) {
      console.log(e);
    }
  };
  const getNationality = () => {
    let res =
      selected?.employee?.nationality?.length === 2
        ? getObjByKey(NATIONALITY, `${selected?.employee?.nationality}`)?.name
        : selected?.employee?.nationality;
    return res;
  };

  const getCountryCode = () =>
    selected?.client?.pendingChanges?.countryCode ||
      selected?.client?.countryCode
      ? countryData?.find(
        (item) =>
          item?.iso ===
          (selected?.client?.pendingChanges?.countryCode ||
            selected?.client?.countryCode)
      )?.code
      : "";

  const renderEmployeeInfoItem = (title, value) => {
    return (
      <li>
        <span>{title}</span>
        <span>{value}</span>
      </li>
    )
  };

  const reGenerateEmployeeInfo = (item, onlyShow = []) => {
    let infoArray = [];
    if (onlyShow?.length ? onlyShow?.includes('molNo') ? item?.molNo : null : item?.molNo) {
      infoArray.push({
        title: 'MOL Number',
        value: item?.molNo
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('eid') ? item?.eid : null : item?.eid) {
      infoArray.push({
        title: 'Emirates Id',
        value: item?.eid
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('displayName') ? item?.displayName : null : item?.displayName) {
      infoArray.push({
        title: 'Display Name',
        value: item?.displayName
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('email') ? item?.email : null : item?.email) {
      infoArray.push({
        title: 'Email',
        value: item?.email
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('establishmentId') ? item?.establishmentId : null : item?.establishmentId) {
      infoArray.push({
        title: 'Linked Establishment Id',
        value: item?.establishmentId
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('empCode') ? item?.empCode : null : item?.empCode) {
      infoArray.push({
        title: 'Emp Code',
        value: item?.empCode
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('passportNumber') ? item?.passportNumber : null : item?.passportNumber) {
      infoArray.push({
        title: 'Passport Number',
        value: item?.passportNumber
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('employeeType') ? item?.employeeType : null : item?.employeeType) {
      infoArray.push({
        title: 'Employee Type',
        value: readableText(item?.employeeType)
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('cardType') ? item?.cardType : null : item?.cardType) {
      infoArray.push({
        title: 'Card Type',
        value: readableText(item?.cardType)
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('mobileNo') ? item?.mobileNo : null : item?.mobileNo) {
      infoArray.push({
        title: 'Mobile Number',
        value: item?.mobileNo
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('altMobileNo') ? item?.altMobileNo : null : item?.altMobileNo) {
      infoArray.push({
        title: 'Alt Mobile Number',
        value: item?.altMobileNo
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('gender') ? item?.gender : null : item?.gender) {
      infoArray.push({
        title: 'Gender',
        value: item?.gender
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('dob') ? item?.dob : null : item?.dob) {
      infoArray.push({
        title: 'Date of Birth',
        value: renderDate(item?.dob)
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('doj') ? item?.doj : null : item?.doj) {
      infoArray.push({
        title: 'Date of Joining',
        value: renderDate(item?.doj)
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('nationality') ? item?.nationality : null : item?.nationality) {
      infoArray.push({
        title: 'Nationality',
        value: item?.nationality?.length === 2 ? getObjByKey(NATIONALITY, `${item?.nationality}`)?.name : item?.nationality
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('kamelpayWalletId') ? item?.kamelpayWalletId : null : item?.kamelpayWalletId) {
      infoArray.push({
        title: 'Wallet Id',
        value: item?.kamelpayWalletId
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('workAddress') ? item?.workAddress : null : item?.workAddress) {
      infoArray.push({
        title: 'Address',
        value: item?.workAddress
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('workStateId') ? item?.workStateId : null : item?.workStateId) {
      infoArray.push({
        title: 'State',
        value: item?.workStateId?.name
      })
    }
    if (onlyShow?.length ? onlyShow?.includes('workZipCode') ? item?.workZipCode : null : item?.workZipCode) {
      infoArray.push({
        title: 'Zip Code',
        value: item?.workZipCode
      })
    }
    if (item?.employeeType === "OTHER_BANK") {
      if (onlyShow?.length ? onlyShow?.includes('otherBank_bankName') ? item?.otherBank?.bankName : null : item?.otherBank?.bankName) {
        infoArray.push({
          title: 'Bank Name',
          value: item?.otherBank?.bankName
        })
      }
      if (onlyShow?.length ? onlyShow?.includes('otherBank_branchName') ? item?.otherBank?.branchName : null : item?.otherBank?.branchName) {
        infoArray.push({
          title: 'Branch Name',
          value: item?.otherBank?.branchName
        })
      }
      if (onlyShow?.length ? onlyShow?.includes('otherBank_iban') ? item?.otherBank?.iban : null : item?.otherBank?.iban) {
        infoArray.push({
          title: 'IBAN',
          value: item?.otherBank?.iban
        })
      }
    }
    return infoArray
  };

  const renderEmployeesData = () => {
    return employees && employees?.length ? (
      <ul className="inner-data">
        <hr />
        <h4>Employees Effected</h4>

        <MappedElement
          data={employees}
          renderElement={(item, i) => {
            let showKeys = selected?.topic === TOPIC?.INACTIVE_EMPLOYEES ? ['eid', 'displayName', 'passportNumber', 'empCode'] : [];
            let data = reGenerateEmployeeInfo(item, showKeys);
            return (
              <>
                <h4>
                  Employee {employees?.length > 1 ? `${i + 1}` : null}:
                </h4>
                <MappedElement
                  data={data}
                  renderElement={(childItem) => {
                    return renderEmployeeInfoItem(childItem?.title, childItem?.value)
                  }}
                />
                {showKeys?.length ? showKeys?.includes('documents') ? renderDocuments(item?.documents, renderImage) : null : renderDocuments(item?.documents, renderImage)}
                <hr />
              </>
            )
          }} />
      </ul>
    ) : null;
  };

  const renderChange = () => {
    return employees && employees?.length ? (
      selected?.topic === "EMPLOYEE_UPDATE_REQUEST" ? (
        <ul className="inner-data">
          <hr />
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.molNo ? (
                  <>
                    <li>
                      <span>MOL Number</span>
                      <span>{data?.pendingChanges?.molNo}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.establishmentId ? (
                  <>
                    <li>
                      <span>Establishment Id</span>
                      <span>{data?.pendingChanges?.establishmentId}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.mobileNo ? (
                  <>
                    <li>
                      <span>Mobile Number</span>
                      <span>{data?.pendingChanges?.mobileNo}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.altMobileNo ? (
                  <>
                    <li>
                      <span>Alternate Mobile Number</span>
                      <span>{data?.pendingChanges?.altMobileNo}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.workAddress ? (
                  <>
                    <li>
                      <span>Address</span>
                      <span>{data?.pendingChanges?.workAddress}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.workStateId?.name ? (
                  <>
                    <li>
                      <span>State</span>
                      <span>{data?.pendingChanges?.workStateId?.name}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.workZipCode ? (
                  <>
                    <li>
                      <span>ZipCode</span>
                      <span>{data?.pendingChanges?.workZipCode}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.otherBank ? (
                  <>
                    {data?.pendingChanges?.otherBank?.bankName ? (
                      <li>
                        <span>Bank Name</span>
                        <span>{data?.pendingChanges?.otherBank?.bankName}</span>
                      </li>
                    ) : null}
                    {data?.pendingChanges?.otherBank?.branchName ? (
                      <li>
                        <span>Branch Name</span>
                        <span>
                          {data?.pendingChanges?.otherBank?.branchName}
                        </span>
                      </li>
                    ) : null}
                    {data?.pendingChanges?.otherBank?.iban ? (
                      <li>
                        <span>IBAN</span>
                        <span>{data?.pendingChanges?.otherBank?.iban}</span>
                      </li>
                    ) : null}
                  </>
                ) : null}
                {renderDocuments(data?.pendingChanges?.documents, renderImage)}
              </>
            );
          })}
        </ul>
      ) : selected?.topic === "CHANGE_EMPLOYEE_TYPE" ? (
        <ul className="inner-data">
          <hr />
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.otherBank?.bankName ? (
                  <>
                    <li>
                      <span>Bank Name</span>
                      <span>{data?.pendingChanges?.otherBank?.bankName}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.otherBank?.branchName ? (
                  <>
                    <li>
                      <span>Branch Name</span>
                      <span>{data?.pendingChanges?.otherBank?.branchName}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.otherBank?.iban ? (
                  <>
                    <li>
                      <span>IBAN</span>
                      <span>{data?.pendingChanges?.otherBank?.iban}</span>
                    </li>
                  </>
                ) : null}
              </>
            );
          })}
        </ul>
      ) : selected?.topic === TOPIC.UPDATE_MOL_NO ? (
        <ul className="inner-data">
          <hr />
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.molNo ? (
                  <>
                    <li>
                      <span>MOL Number</span>
                      <span>{data?.pendingChanges?.molNo}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.establishmentId ? (
                  <>
                    <li>
                      <span>Establishment Id</span>
                      <span>{data?.pendingChanges?.establishmentId}</span>
                    </li>
                  </>
                ) : null}
              </>
            );
          })}
        </ul>
      ) : selected?.topic === TOPIC.EMPLOYEE_EID_UPDATE ? (
        <ul className="inner-data">
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.eid ? (
                  <>
                    <li>
                      <span>Emirates Id</span>
                      <span>{data?.pendingChanges?.eid}</span>
                    </li>
                  </>
                ) : null}
                {data?.pendingChanges?.eidExpiry ? (
                  <>
                    <li>
                      <span>EID Expiry</span>
                      <span>{renderDate(data?.pendingChanges?.eidExpiry)}</span>
                    </li>
                  </>
                ) : null}
                <h4>EID Images</h4>
                <MappedElement
                  data={data?.pendingChanges?.eidUrls}
                  empty={() => null}
                  renderElement={(item, i) => {
                    return (
                      <>
                        {renderImage(
                          item,
                          "Emirates Id Image",
                          renderDate(data?.pendingChanges?.eidExpiry)
                        )}
                      </>
                    );
                  }}
                />
              </>
            );
          })}
        </ul>
      ) : selected?.topic === TOPIC.EMPLOYEE_PHONE_UPDATE ? (
        <ul className="inner-data">
          <hr />
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.mobileNo ? (
                  <>
                    <li>
                      <span>Mobile Number</span>
                      <span>{data?.pendingChanges?.mobileNo}</span>
                    </li>
                  </>
                ) : null}
              </>
            );
          })}
        </ul>
      ) : selected?.topic === TOPIC.EMPLOYEE_STATEMENT_REQUEST ? (
        <ul className="inner-data">
          <h4>Statement Request:</h4>
          {selected?.statementRequestTime?.from ? (
            <>
              <li>
                <span>From Date</span>
                <span>{renderDate(selected?.statementRequestTime?.from)}</span>
              </li>
            </>
          ) : null}
          {selected?.statementRequestTime?.to ? (
            <>
              <li>
                <span>To Date</span>
                <span>{renderDate(selected?.statementRequestTime?.to)}</span>
              </li>
            </>
          ) : null}
        </ul>
      ) : selected?.topic === TOPIC.UPDATE_DATE_OF_BIRTH ? (
        <ul className="inner-data">
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.dob ? (
                  <>
                    <li>
                      <span>Date Of Birth</span>
                      <span>{renderDate(data?.pendingChanges?.dob)}</span>
                    </li>
                  </>
                ) : null}
              </>
            );
          })}
        </ul>
      ) : selected?.topic === TOPIC.UPDATE_EMP_CODE ? (
        <ul className="inner-data">
          <h4>Changes:</h4>
          {employees?.map((data, i) => {
            return (
              <>
                {data?.pendingChanges?.empCode ? (
                  <>
                    <li>
                      <span>Emp Code</span>
                      <span>{data?.pendingChanges?.empCode}</span>
                    </li>
                  </>
                ) : null}
              </>
            );
          })}
        </ul>
      ) : selected?.topic === TOPIC.UPDATE_PASSPORT ? (
        <ul className="inner-data">
          <h4>Changes:</h4>
          {employees?.map((data) => {
            return renderDocuments(data?.pendingChanges?.documents, renderImage);
          })}
        </ul>
      ) : null
    ) : null;
  };

  const renderApprovalTime = () => {
    return (
      <ul className="inner-data">
        <hr />
        {selected?.createdAt ? (
          <li>
            <span>Created At</span>
            <span>{renderDateAndTime(selected?.createdAt)}</span>
          </li>
        ) : null}
        {selected?.updatedAt ? (
          <li>
            <span>Last Updated At</span>
            <span>{renderDateAndTime(selected?.updatedAt)}</span>
          </li>
        ) : null}
      </ul>
    );
  };

  const renderCreatedBy = () => {
    return selected?.createdBy && Object.keys(selected?.createdBy)?.length ? (
      <ul className="inner-data">
        <hr />
        <h4>Created By:</h4>
        <li>
          <span>Name</span>
          <span>
            {selected?.createdBy?.user?.name ||
              selected?.createdBy?.user?.companyName}{" "}
            ({readableText(selected?.createdBy.role)})
          </span>
        </li>
        <li>
          <span>Email</span>
          <span>{selected?.createdBy?.user?.email}</span>
        </li>
      </ul>
    ) : null;
  };

  const renderAction = () => {
    return selected?.approvalTracking && selected?.approvalTracking?.length ? (
      <ul className="inner-data">
        <hr />
        <h4>Action Performed By</h4>
        {selected?.approvalTracking?.map((item, i) => {
          return (
            <>
              <h3>Action {i + 1}:</h3>
              {item?.approvedBy?.companyName || item?.approvedBy?.name ? (
                <li>
                  <span>Name</span>
                  <span>{`${item?.approvedBy?.companyName || item?.approvedBy?.name
                    }`}</span>
                </li>
              ) : null}
              {item?.approvedBy ? (
                <li>
                  <span>Email</span>
                  <span>{item?.approvedBy?.email}</span>
                </li>
              ) : null}
              {item?.action ? (
                <li>
                  <span>Action</span>
                  <span>{`${readableText(item?.action)}`}</span>
                </li>
              ) : null}
              {item?.time ? (
                <li>
                  <span>Time</span>
                  <span>{`${renderDateAndTime(item?.time)}`}</span>
                </li>
              ) : null}
            </>
          );
        })}
        {selected?.actionReview ? (
          <>
            <hr />
            <li>
              <span>Review</span>
              <span>{`${selected?.actionReview}`}</span>
            </li>
          </>
        ) : null}
      </ul>
    ) : null;
  };

  const renderChildCompanyDetail = () => {
    return selected?.childCompany?.clientId ? (
      <ul className="inner-data">
        <hr />
        <h4>Other Company Detail</h4>
        {selected?.childCompany?.clientId ? (
          <li>
            <span>Client Id</span>
            <span>{`${selected?.childCompany?.clientId}`}</span>
          </li>
        ) : null}
        {selected?.childCompany?.pendingChanges?.establishmentId ? (
          <li>
            <span>Establishment Id</span>
            <span>{`${selected?.childCompany?.pendingChanges?.establishmentId}`}</span>
          </li>
        ) : null}
        {selected?.childCompany?.trnNumber ? (
          <li>
            <span>TRN Number</span>
            <span>{`${selected?.childCompany?.trnNumber}`}</span>
          </li>
        ) : null}
        {selected?.childCompany?.companyName ? (
          <li>
            <span>Company Name</span>
            <span>{`${selected?.childCompany?.companyName}`}</span>
          </li>
        ) : null}
        {selected?.childCompany?.email ? (
          <li>
            <span>Email</span>
            <span>{`${selected?.childCompany?.email}`}</span>
          </li>
        ) : null}
        {renderDocuments(selected?.childCompany?.pendingChanges?.documents, renderImage)}
      </ul>
    ) : null;
  };

  const renderEstablishmentIds = (data) => {
    return data?.length
      ? data?.map((item, i) => {
        return (
          <>
            <hr />
            {item?.establishmentId ? (
              <li>
                <span>Establishment Id</span>
                <span>{`${item?.establishmentId}`}</span>
              </li>
            ) : null}
            {item?.trnNumber ? (
              <li>
                <span>TRN Number</span>
                <span>{`${item?.trnNumber}`}</span>
              </li>
            ) : null}
            {item?.companyName ? (
              <li>
                <span>Company Name</span>
                <span>{`${item?.companyName}`}</span>
              </li>
            ) : null}
          </>
        );
      })
      : null;
  };

  const renderExchangeHouse = (data) => {
    return data && Object.keys(data)?.length ? (
      <ul className="inner-data">
        <hr />
        <h4>Head Office</h4>
        {data?.exchangeHouseId ? (
          <li>
            <span>Head Office Id</span>
            <span>{data?.exchangeHouseId}</span>
          </li>
        ) : null}
        {data?.companyName ? (
          <li>
            <span>Company Name</span>
            <span>{data?.companyName}</span>
          </li>
        ) : null}
        {data?.email ? (
          <li>
            <span>Email</span>
            <span>{data?.email}</span>
          </li>
        ) : null}
        {data?.phone ? (
          <li>
            <span>Phone</span>
            <span>{data?.phone}</span>
          </li>
        ) : null}
      </ul>
    ) : null;
  };

  const renderBranch = (data) => {
    return data && Object.keys(data)?.length ? (
      <ul className="inner-data">
        <hr />
        <h4>Branch</h4>
        {data?.exchangeHouseBranchId ? (
          <li>
            <span>Branch Id</span>
            <span>{data?.exchangeHouseBranchId}</span>
          </li>
        ) : null}
        {data?.companyName ? (
          <li>
            <span>Branch Name</span>
            <span>{data?.companyName}</span>
          </li>
        ) : null}
        {data?.location ? (
          <li>
            <span>Location</span>
            <span>{data?.location}</span>
          </li>
        ) : null}
        {data?.email ? (
          <li>
            <span>Email</span>
            <span>{data?.email}</span>
          </li>
        ) : null}
        {data?.phone ? (
          <li>
            <span>Phone</span>
            <span>{data?.phone}</span>
          </li>
        ) : null}
        {data?.establishmentId ? (
          <li>
            <span>Establishment Id</span>
            <span>{data?.establishmentId}</span>
          </li>
        ) : null}
      </ul>
    ) : null;
  };

  const renderContactDetails = () => {
    return selected?.client?.contactDetails && Object.keys(selected?.client)?.length && Object.keys(selected?.client?.contactDetails)?.length ? (
      <>
        <hr />
        <h4>Contact Details</h4>
        {selected?.client?.pendingChanges?.contactDetails?.address ||
          selected?.client?.contactDetails?.address ? (
          <li>
            <span>Address</span>
            <span>
              {selected?.client?.pendingChanges?.contactDetails?.address ||
                selected?.client?.contactDetails?.address}
            </span>
          </li>
        ) : null}
        {selected?.client?.pendingChanges?.contactDetails?.building ||
          selected?.client?.contactDetails?.building ? (
          <li>
            <span>Building Number</span>
            <span>
              {selected?.client?.pendingChanges?.contactDetails?.building ||
                selected?.client?.contactDetails?.building}
            </span>
          </li>
        ) : null}
        {selected?.client?.pendingChanges?.contactDetails?.zipCode ||
          selected?.client?.contactDetails?.zipCode ? (
          <li>
            <span>Zip Code</span>
            <span>
              {selected?.client?.pendingChanges?.contactDetails?.zipCode ||
                selected?.client?.contactDetails?.zipCode}
            </span>
          </li>
        ) : null}
        {selected?.client?.pendingChanges?.contactDetails?.city ||
          selected?.client?.contactDetails?.city ? (
          <li>
            <span>City</span>
            <span>
              {selected?.client?.pendingChanges?.contactDetails?.city ||
                selected?.client?.contactDetails?.city}
            </span>
          </li>
        ) : null}
        {selected?.client?.pendingChanges?.contactDetails?.state?.name ||
          selected?.client?.contactDetails?.state?.name ? (
          <li>
            <span>State</span>
            <span>
              {selected?.client?.pendingChanges?.contactDetails?.state?.name ||
                selected?.client?.contactDetails?.state?.name}
            </span>
          </li>
        ) : null}
      </>
    ) : null;
  };

  return reduxState?.loading ? (
    <CLoading position="relative" className="h-100" />
  ) : selected && Object.keys(selected)?.length ? (
    <>
      <div className="c-data col overflow-visible">
        {selected?.topic !== TOPIC?.EMPLOYEE_ONBOARD_REQUEST ? (
          <ul className="inner-data">
            {selected?.approvalId ? (
              <>
                <li>
                  <span>Approval Id</span>
                  <span>{`${selected?.approvalId}`}</span>
                </li>
                <hr />
              </>
            ) : null}

            {selected?.client?.pendingChanges?.establishmentId ? (
              <h4>Client: </h4>
            ) : null}
            {selected?.client?.pendingChanges?.establishmentId ||
              selected?.client?.establishmentId ? (
              <>
                <li>
                  <span>Establishment Id</span>
                  <span>
                    {selected?.client?.pendingChanges?.establishmentId ||
                      selected?.client?.establishmentId}
                  </span>
                </li>
              </>
            ) : null}
            {selected?.pendingChanges?.trnNumber ||
              selected?.client?.trnNumber ? (
              <>
                <li>
                  <span>TRN Number</span>
                  <span>
                    {selected?.pendingChanges?.trnNumber ||
                      selected?.client?.trnNumber}
                  </span>
                </li>
              </>
            ) : null}

            {selected?.client?.pendingChanges?.companyName ||
              selected?.client?.companyName ? (
              <li>
                <span>Company Name</span>
                <span>{`${selected?.client?.pendingChanges?.companyName ||
                  selected?.client?.companyName
                  }`}</span>
              </li>
            ) : null}

            {selected?.client?.pendingChanges?.phone ||
              selected?.client?.phone ? (
              <li>
                <span>Phone Number</span>
                <span>{`${getCountryCode()} ${getPhoneNumber(selected?.client?.pendingChanges?.phone || selected?.client?.phone, selected?.client?.pendingChanges?.countryCode || selected?.client?.countryCode)
                  }`}</span>
              </li>
            ) : null}

            {selected?.client?.pendingChanges?.email ||
              selected?.client?.email ? (
              <li>
                <span>Email</span>
                <span>{`${selected?.client?.pendingChanges?.email ||
                  selected?.client?.email
                  }`}</span>
              </li>
            ) : null}

            {selected?.pendingChanges?.payrollTypes || selected?.payrollTypes ? (
              <li>
                <span>PayrollTypes</span>
                <span>{selected?.pendingChanges?.payrollTypes || selected?.payrollTypes}</span>
              </li>
            ) : null}
            {(selected?.client?.pendingChanges && selected?.client?.pendingChanges?.hasOwnProperty("isFreeZone")) ||
              (selected?.client && selected?.client?.hasOwnProperty("isFreeZone")) ? <li>
              <span>Free Zone</span>
              <span>{`${selected?.client?.pendingChanges?.isFreeZone ||
                selected?.client?.isFreeZone ? "Yes" : "No"}`}</span>
            </li> : null}

            {renderContactDetails()}
            {selected?.topic === "ESTABLISHMENT_IDS_ADDED" &&
              selected?.client &&
              selected?.client?.pendingChanges &&
              selected?.client?.pendingChanges?.linkedEstablishmentIds &&
              selected?.client?.pendingChanges?.linkedEstablishmentIds?.length ? (
              <>
                <h4>Establishment Ids:</h4>
                {renderEstablishmentIds(
                  selected?.client?.pendingChanges?.linkedEstablishmentIds
                )}
              </>
            ) : null}
            {renderDocuments(documents, renderImage)}

            {selected?.topic === TOPIC?.ESTABLISHMENT_ID_ADDED &&
              selected?.client?.pendingChanges?.linkedEstablishmentIds?.length ? (
              <>
                <hr />
                <h4>Establishment Detail</h4>
                <MappedElement
                  data={
                    selected?.client?.pendingChanges?.linkedEstablishmentIds
                  }
                  empty={() => null}
                  renderElement={(item, i) => {
                    return (
                      <>
                        {item?.companyName || item?.companyName ? (
                          <li>
                            <span>Company Name</span>
                            <span>{item?.companyName}</span>
                          </li>
                        ) : null}
                        {item?.establishmentId || item?.establishmentId ? (
                          <li>
                            <span>Establishment Id</span>
                            <span>{item?.establishmentId}</span>
                          </li>
                        ) : null}
                        {item?.trnNumber ? (
                          <li>
                            <span>TRN Number</span>
                            <span>{item?.trnNumber}</span>
                          </li>
                        ) : null}
                        <hr />
                        {item?.documentNumber ? (
                          <li>
                            <span>Trade License Number</span>
                            <span>{item?.documentNumber}</span>
                          </li>
                        ) : null}
                        {item?.issueDate || item?.issueDate ? (
                          <li>
                            <span>Issue Date</span>
                            <span>{renderDate(item?.issueDate)}</span>
                          </li>
                        ) : null}
                        {item?.expiryDate || item?.expiryDate ? (
                          <li>
                            <span>Expiry Date</span>
                            <span>{renderDate(item?.expiryDate)}</span>
                          </li>
                        ) : null}
                        {item?.url ? renderImage(item, "Trade License") : null}
                      </>
                    );
                  }}
                />
              </>
            ) : null}

            {selected?.topic === TOPIC.CENTIV_LOAD_FUNDS ? (
              <>
                <hr />
                <li>
                  <span>Load Fund Amount</span>
                  <span>{formatter(selected?.loadFundCentivAmount || 0)}</span>
                </li>
              </>
            ) : null}

            {selected?.topic === TOPIC.SALARY_FILE_UPLOAD ? (
              <>
                <h4>Salary File</h4>
                {selected?.salaryFileAmount ? (
                  <li>
                    <span>Salary File Amount</span>
                    <span>{formatter(selected?.salaryFileAmount)}</span>
                  </li>
                ) : null}
                <li>
                  <span>End of Service employees?</span>
                  <span>{selected?.isEosFile ? 'Yes' : 'No'}</span>
                </li>
                {selected?.salaryFileTotalEmployees ? (
                  <li>
                    <span>Total Employees</span>
                    <span>{selected?.salaryFileTotalEmployees}</span>
                  </li>
                ) : null}
                {selected?.unModifiedSalaryFile
                  ? renderImage(
                    selected?.unModifiedSalaryFile,
                    "Original Salary File"
                  )
                  : null}
                {selected?.salaryErrorFile ? renderImage(selected?.salaryErrorFile, "Salary File Errors") : null}
                {renderImage(selected?.salaryFile, "Modified Salary File")}
                {selected?.salaryFileDate || selected?.salaryFileDate ? (
                  <li>
                    <span>Salary Month</span>
                    <span>
                      {moment(selected?.salaryFileDate).format("MMM-YYYY")}
                    </span>
                  </li>
                ) : null}
              </>
            ) : selected?.topic === TOPIC.DEPOSIT_FILE_UPLOAD ? (
              <>
                <h4>Fund Transfer Receipt</h4>
                {renderImage(selected?.depositSlipFile, "Fund Transfer Receipt")}
              </>
            ) : null}

            {selected?.depositSlipAmount ? (
              <>
                <hr />
                <li>
                  <span>Fund Transfer Amount</span>
                  <span>{`${formatter(selected?.depositSlipAmount)}`}</span>
                </li>
              </>
            ) : null}
            {selected?.paymentMode ? (
              <>
                <li>
                  <span>Payment Mode</span>
                  <span>{`${_readableTextCap(selected?.paymentMode)}`}</span>
                </li>
              </>
            ) : null}
            {selected?.linkedSalaryFiles &&
              selected?.linkedSalaryFiles?.length ? (
              <>
                <h4>Attached Salary Files</h4>
                <MappedElement
                  data={selected?.linkedSalaryFiles}
                  renderElement={(item, i) => {
                    return renderImage(
                      item?.url,
                      "Salary Files",
                      moment(item?.salaryFileDate)?.format("MMM-YYYY")
                    );
                  }}
                />
              </>
            ) : null}
          </ul>
        ) : selected?.employee && Object?.keys(selected?.employee)?.length ? (
          <ul className="inner-data">
            <hr />
            {selected?.approvalId ? (
              <li>
                <span>Approval Id</span>
                <span>{`${selected?.approvalId}`}</span>
              </li>
            ) : null}
            {selected?.employee?.molNo ? (
              <>
                <li>
                  <span>MOL Number</span>
                  <span>{selected?.employee?.molNo}</span>
                </li>
              </>
            ) : null}

            {selected?.employee?.displayName ? (
              <li>
                <span>Display Name</span>
                <span>{`${selected?.employee?.displayName}`}</span>
              </li>
            ) : null}
            {selected?.employee?.nationality ? (
              <li>
                <span>Nationality</span>
                <span>{getNationality()}</span>
              </li>
            ) : null}
            {selected?.employee?.mobileNo ? (
              <li>
                <span>Mobile Number</span>
                <span>{`${selected?.employee?.mobileNo ||
                  selected?.employee?.altMobileNo
                  }`}</span>
              </li>
            ) : null}

            {selected?.employee?.email ? (
              <li>
                <span>Email</span>
                <span>{`${selected?.employee?.email}`}</span>
              </li>
            ) : null}

            {renderDocuments(documents, renderImage)}
          </ul>
        ) : selected?.approvalId ? (
          <ul className="inner-data">
            <li>
              <span>Approval Id</span>
              <span>{`${selected?.approvalId}`}</span>
            </li>
          </ul>
        ) : null}

        {renderExchangeHouse(selected?.exchangeHouse)}

        {renderBranch(selected?.exchangeHouseBranch)}

        {selected?.employees_pdf ? (
          <>
            <h4>Employees File List</h4>
            {renderImage(selected?.employees_pdf, "Employees List")}
          </>
        ) : null}

        {selected?.establishmentIdsFile && selected?.topic === TOPIC.BULK_ESTABLISHMENT_IDS_ADDED ? (
          <>
            <hr />
            <h4>Establishment Ids</h4>
            {renderImage(selected?.establishmentIdsFile, "Establishment Ids")}
          </>
        ) : null}

        {renderChildCompanyDetail()}

        {renderCreatedBy()}

        {renderEmployeesData()}

        {renderChange()}
        {renderApprovalTime()}
        {renderAction()}
      </div>
      {(selected?.approvalTrackingStatus && (CLIENT_APPROVAL_STATUS?.APPROVED_BY_CLIENT_ADMIN === selected?.approvalTrackingStatus) || !selected?.approvalTrackingStatus)
        && (isUser ? reduxState?.user?.checker : true) ? (
        <div className="btn-group">
          <CPopup
            element={
              <CButton
                buttonLayout="green-outline round"
                title="Approve"
                disabled={loading}
                loading={loading}
              />
            }
            onConfirm={() => takeAction(selected, ACTION?.APPROVED)}
            message="Are you sure you want to approve?"
            disabled={loading}
          />
          <CButton
            buttonLayout="red-outline round"
            title="Reject"
            disabled={loading}
            loading={loading}
            onClick={() => toggleRemarksDrawer(true, selected)}
          />
        </div>
      ) : null}
    </>
  ) : (
    <Empty
      description={"No data found, Please try again!"}
      className="d-flex col justify-center h-100"
    />
  );
}
export default Details;
